.invited-users-list {


  .invited-users {
    display: flex;

    .invited-user {
      display: grid;
      grid-template-columns: 1fr 25px;
      align-items: center;

      button {
        display: flex;
        justify-self: end;
      }
    }
  }
}
