@import "src/assets/scss/colors";

.celebration-modal {

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    border: 0;
    position: relative;

    img {
      width: 100%;
    }

    h1 {
      position: absolute;
      top: 50px;
      width: 100%;
      text-align: center;

      font-size: 26px;
      font-weight: 800;
      line-height: 0.92;
      color: $darkBlue;
    }

    .explanation-wrapper {
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tk-academy-text {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
