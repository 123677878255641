.add-button {
  position: sticky !important;
  left: 100px !important;
  bottom: 0 !important;
  cursor: pointer;
  width: 100px;

  img {
    height: 100%;
    width: 100%;
  }
}
