.course-icon-wrapper {
  position: relative;

  .course-icon {
    max-width: 110px;
    border-radius: 5px;
  }

  .test-banner {
    position: absolute;
    bottom: 0;
    width: 100%;

    background-color: #247dc5;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
