@import "../../../../../assets/scss/colors";

.module-sub-item {
  border-bottom: 0.5px solid rgba($keyBlue30, 0.25);

  .status-icon {
    &:before {
      background-color: $white;
      background-size: 90%;
      border-radius: 50px;
    }
  }
}
