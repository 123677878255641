.redirect-to-mobile-screen {
  display: none;
  background-image: url("./appscreen-background.png");
  background-size: cover;
  background-color: white;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    display: block;
    z-index: 999;
    pointer-events: all;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
  }


  img {
    max-width: 190px;
  }

  .hello-background {
    background-image: url("../../../../assets/images/hello.png");
    background-position: bottom center;
    background-size: 345px auto;
    background-repeat: no-repeat;
  }
}

html.no-overflow {
  overflow: hidden;

  body, #root {
    overflow: hidden;
  }
}
