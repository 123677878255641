.activation-screen {
  .registration-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-image: url("../../../../assets/images/registration-background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .registration-foreground {
    z-index: 1;
  }

}
