@import "colors";

.validated-field {
  input[type=text], input[type=password], input[type=email] {
    background-color: white;
    border-radius: 1px;
    border: 0;
    height: 45px;
    color: $darkBlue;
  }
}

textarea {
  background-color: white;
  border-radius: 1px;
  border: 0;
  width: 100%;
  padding: 10px;
}

.react-select {
  input {
    height: inherit !important;
  }

  .react-select__control {
    border: 0;
    height: 45px;
    border-radius: 10px;
  }

  .react-select__value-container {
    height: 45px;
  }

  .react-select__indicator-separator {
    display: none;
  }
}
