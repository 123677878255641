@import "src/assets/scss/colors";

.billing {
  .logo {
    max-height: 30px;
  }

  .main-content {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 20px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .content-container {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .checkout-card {
    border-radius: 10px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #f0f0f0;
    background-color: white;
  }

  .footer {
    border-top: 5px solid $keyGreen;

    .logo-footer {
      max-height: 30px;
    }

    .footer-content {
      display: grid;
      grid-template-columns: 3fr 8fr 1fr;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        row-gap: 20px;
      }

      .text {

      }
    }

    .menu-wrapper {
      ul {
        list-style: none;
        padding-left: 0;
      }
    }
  }
}
