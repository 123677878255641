@import "../../../assets/scss/colors";

.edit-qna-modal {

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    border: 0;
    position: relative;
    background-color: $white;

  }
}
