@import "src/assets/scss/colors";

.checkout-form {

  .tk-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      row-gap: 0 !important;
    }


    .react-select {
      border-radius: 1px;
      border: solid 1px #e9eef8;

      .react-select__single-value {
        font-size: 14px;
        line-height: 1.43;
        color: $waterBlue80;
      }

      .react-select__value-container {
        padding-left: 10px;
      }

      .react-select__placeholder {
        font-size: 14px;
        line-height: 1.43;
        color: $waterBlue80;
        opacity: 0.7;
      }
    }

    input {
      border-radius: 1px;
      border: solid 1px #e9eef8;
      font-size: 14px;
      line-height: 1.43;
      color: $waterBlue;
      height: 45px;

      &::placeholder {
        color: $waterBlue80;
        opacity: 0.7;
      }

    }
  }
}
