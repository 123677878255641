.onboarding {
  .modal-content {
    border-radius: 20px;
    overflow: hidden;
  }

  .onboarding-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 300px;
    }
  }

  .onboarding-slide {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .carousel-control-prev {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .carousel-control-next {
    justify-content: flex-end;
    padding-right: 20px;
  }

  .control-icon {
    color: #247dc5;
    font-size: 26px;
  }

  .carousel-indicators {
    left: 50%;
    padding-bottom: 30px;

    li {
      height: 6px !important;
      width: 6px !important;
      border-radius: 15px !important;
      background-color: #a7cbe8 !important;
      border: none;

      &.active {
        background-color: #247dc5 !important;
      }
    }
  }
}
