.header-background-slot {
  position: absolute;
  width: 100%;
  z-index: 1;

  .button-wrapper {
    margin-left: auto;
    margin-top: 15px;
    margin-right: 30px;
  }
}
