@mixin heading {
  font-size: 22px;
  line-height: 1.27;
}

.heading {
  @include heading;
}

.tk-heading-bold {
  @include heading;
  font-weight: 800;
}

.tk-academy-small-text {
  font-size: 12px;
  line-height: 1.67;
}

.tk-academy-huge-bold-text {
  font-size: 55px;
  line-height: 1.18;
  font-weight: 800;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
}

.tk-academy-huge-text {
  font-size: 45px;
  line-height: 1.22;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
}

.tk-academy-extra-large-heading {
  font-size: 26px;
  line-height: 1.22;
}

.tk-academy-extra-large-heading-bold {
  font-size: 26px;
  line-height: 1.22;
  font-weight: 800;
}

.tk-academy-larger-bold {
  font-size: 22px;
  font-weight: 800;
  line-height: 1.5;
}

.tk-academy-larger {
  font-size: 22px;
  line-height: 1.5;
}

.tk-academy-large {
  font-size: 16px;
  line-height: 1.5;
}

.tk-academy-large-bold {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
}

.tk-academy-text {
  font-size: 14px;
  line-height: 1.43;
}

.tk-academy-normal {
  font-weight: normal;
}

.tk-academy-bold {
  font-weight: bold;
}

.tk-academy-semibold {
  font-weight: 600;
}
