@import "src/assets/scss/colors";

.text-element-display {
  display: flex;
  padding: 100px;
  overflow: scroll;
  flex-grow: 1;

  justify-content: center;

  .wrapper {
    max-width: 768px;


    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.43;
    }

    img {
      max-width: 100%
    }
  }
}
