@import "../../../../assets/scss/colors";

.switch-wrapper {
  display: grid !important;


  &.title-right {
    grid-template-columns: 40px 1fr;
  }

  &.title-left {
    grid-template-columns:1fr 40px;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $orange;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    left: -5px;
    bottom: -1px;
  }

  input:checked + .slider {
    background-color: $keyGreen;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $keyGreen;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}
