@import "../../../assets/scss/colors";

.order-list {
  .order {
    display: grid;
    grid-template-columns: 150px 1fr 100px 150px;

    a {
      color: $keyBlue;
    }
  }
}
