@import "src/assets/scss/colors";

.multiple-choice-element {

  .image-wrapper {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;

    img {
      max-width: 600px;
    }
  }

  .answer {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    font-size: 16px;
    line-height: 2;
    color: $darkBlue;

    &.has-image {
      .answer-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 300px;
          margin-bottom: 20px;
        }
      }
    }



    &.checked {
      border-left: 5px solid $keyBlue !important;
    }
  }
}
