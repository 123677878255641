.course-sidebar {
  display: flex;

  .menu-column {
    width: 60px;
  }

  .submenu-column {
    flex-grow: 1;
    width: 300px;
    max-height: 100%;

    .padded-block {
    }

    img {
      max-width: 170px
    }
  }

  .course-image-wrapper {
    img {
      max-height: 150px;
    }
  }

}
