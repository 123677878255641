.add-button {
  position: absolute;
  right: 50px;
  bottom: 50px;
  cursor: pointer;

  img {
    height: 100px;
    width: 100px;
  }
}
