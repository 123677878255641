.streak-display {
  background-image: url(./streak-display-background.png);
  background-size: cover;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .days {
    display: flex;

    div {
      font-size: 14px;
      font-weight: bold;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;

      width: 30px;
      height: 30px;
      border: dotted 2px white;
      border-radius: 35px;

      &.active {
        border: solid 2px white;
      }
    }
  }
}
