.validated-field {
  input[type=text], input[type=password], input[type=email] {
    background-color: white;
    border-radius: 1px;
    border: 0;
    height: 45px;
  }

  .validation-error {

  }
}
