@import "../../../../../assets/scss/colors";

.course-card {
  .ihk-image {
    max-width: 32px;
  }

  .progress-bar-wrapper {
    border-top: 1px solid $waterBlue10;
  }
}
