@import "src/assets/scss/colors";

.order-certificate-modal {

  .two-column-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  .celebration-modal-content {
    border-radius: 10px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    border: 0;
    position: relative;
    padding: 20px;
  }
}
