@import "src/assets/scss/colors";

.achievement-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-wrapper {
    border-radius: 50px;
    margin-bottom: 10px;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
    }

    &.has-achievement {
      border: solid 2px $keyGreen;
      background-color: $white;
    }

    .tick {
      position: absolute;
      color: $keyGreen;
      top: 5px;
      right: 5px;
      z-index: 5;

      span:before {
        background-color: white;
        border-radius: 100px;
      }
    }
  }


  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: $waterBlue80;
  }
}
