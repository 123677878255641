@import './src/assets/scss/colors';

.failed-cloze-element {
  .cloze-text {
    line-height: 2;
  }

  .word-area {
    border-radius: 10px;
    background-color: $waterBlue10;
    margin-bottom: 50px;
  }

  .draggable-word {
    border-radius: 5px;
    border: solid 1px #d0dae4;
    background-color: $white;
    cursor: pointer;

    padding: 5px 10px;

    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.16px;
    margin-right: 5px;
    color: $keyBlue;
  }

  .word-wrapper {
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.16px;
    margin: 0 5px;
    display: inline-block;
    width: fit-content;
  }

  .word-wrapper:first-of-type {
    margin-left: 0;
  }

  .mistake {
    color: $orange;
    background-color: $orange20;
    border: solid 1px #d0dae4;
  }

  .correct {
    color: $keyGreen;
    border: solid 1px #50cd82;
    background-color: transparent;
  }
}