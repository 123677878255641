.user-cards {
  .user-card-header {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 150px 100px;
  }

  .course-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .add-user-button img {
    height: 25px;
    width: 25px;
  }
}
