@import "../../../../assets/scss/colors";

.radio-wrapper {
  .checkbox {
    border: solid 1.5px #d0dae4;
    background-color: white;
    border-radius: 15px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .radio-bubble {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: $keyBlue
    }
  }
}
