.customer-type-select-field {

  .disabled {
    .checkmark {
      border-color: lighten(#96aac1, 10%);
    }

    .tk-academy-text {
      color: lighten(#96aac1, 10%);
    }
  }

  /* Customize the label (the radio-wrapper) */
  .radio-wrapper {
    display: flex !important;
    align-items: center !important;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: solid 1px #96aac1;
    background-color: white;
    border-radius: 50%;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-wrapper input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-wrapper .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #247ac7;
  }


}
