@import "../../../assets/scss/colors";

ul.menu {
  list-style: none;

  .icon {
    font-size: 18px;
  }

  a:hover, .btn-link:hover {
    color: $white;
    text-decoration: none;
  }

  .root-item:not(.has-subitems), .only-link-subitem {
    border-bottom: 0.5px solid rgba($keyBlue30, 0.25);
  }
}
