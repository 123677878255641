.screen {
  max-height: 100%;

  .content-area {
    z-index: 2;
    border-top-left-radius: 25px;
    overflow: scroll;

    .inner-content {
      width: 100%;
      height: 100%;
      min-width: 768px;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .floating-footer-wrapper {
    bottom: 0;
    z-index: 5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}
