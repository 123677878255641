@import "colors";

html {
  scroll-behavior: smooth;
}

div, span, a, h1, h2, h3, h4, h5, h6, button, p, select, option, textarea, input {
  font-family: "Nunito Sans", sans-serif;
}

@import "primereact/carousel/carousel.min.css";
@import "primereact/dialog/dialog.min.css";
@import "primeicons/primeicons.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-image-lightbox/style.css";
@import "typography";
@import "forms";
@import "~react-datepicker/dist/react-datepicker.min.css";

h1, h2, h3, h4, h5, p {
  margin: 0;
}

button {
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: inherit;
}

.btn:not(.btn-link) {
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  text-align: center;
  border-radius: 50px;
}

.btn-primary {
  color: white;
  background-color: $keyGreen;
  border: 0 !important;
  outline: none !important;

  &:hover {
    background-color: darken($keyGreen, 10%);
  }

  &:disabled {
    background-color: $keyGreen;
  }

  &:active, &:focus {
    background-color: $keyGreen !important;
    box-shadow: none !important;
  }
}

.btn-skip-or-repeat {
  color: $waterBlue80;
  background: transparent;
  border: 1px solid #466890 !important;
  outline: none !important;
  cursor: pointer;
}

.btn-danger {
  color: white;
  background-color: $orange;
  border: 0 !important;
  outline: none !important;

  &:hover {
    background-color: darken($orange, 10%);
  }

  &:disabled {
    background-color: $orange;
  }

  &:active, &:focus {
    background-color: $orange !important;
    box-shadow: none !important;
  }
}

.btn-secondary {
  color: white;
  background-color: $keyBlue;
  border: 0 !important;
  outline: none !important;

  &:hover {
    background-color: darken($keyBlue, 10%);
  }

  &:disabled {
    background-color: $keyBlue;
  }

  &:active, &:focus {
    background-color: $keyBlue !important;
    box-shadow: none !important;
  }
}

.btn-outline-secondary {
  color: $keyBlue;
  background-color: $white;
  border-color: $keyBlue;

  &:hover {
    color: $white !important;
    border-color: darken($keyGreen, 10%);
    background-color: darken($keyGreen, 10%);
  }

  &:disabled {
    color: $keyGreen;
    border-color: $keyGreen;
  }

  &:hover:disabled {
    color: $keyBlue !important;
    background-color: $white !important;
    border-color: $keyBlue !important;
  }

  &:active, &:focus {
    background-color: $white !important;
    box-shadow: none !important;
  }
}

.margin-top-minus-menu {
  margin-top: -100px;
}

a, .btn-link {
  color: $keyBlue;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

#root {
  height: 100%;
  width: 100vw;
}

html, body {
  height: 100% !important;
}

p {
  white-space: pre-line;
}

.left-0 {
  left: 0
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-15 {
  top: 15px;
}

.top-20 {
  top: 20px;
}

.right-0 {
  right: 0;
}

.right-20 {
  right: 20px;
}

.left15 {
  left: 15px
}

.zIndex10 {
  z-index: 10;
}

.tk-academy-shadow {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  border: solid 0.5px #d0d9e3;
}

.tk-academy-card {
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background-color: $white;
}

.headerBar {
  height: 60px;
}

.tk-border-radius {
  border-radius: 15px;
}

.tk-cursor-pointer {
  cursor: pointer;
}

.tk-cursor-na {
  cursor: not-allowed;
}

.line-through {
  text-decoration: line-through;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    padding: 0.375rem 0.75rem;
    width: 100%;
  }
}

.alert-info {
  background-color: #247dc5;
  color: white;
}

button:focus {
  outline: 0 !important;
}
