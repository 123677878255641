@import "../../../../../assets/scss/colors";

.lesson-image {
  .locked-overlay {
    -webkit-backdrop-filter: blur(1.8px);
    backdrop-filter: blur(1.8px);
    background-color: $waterBlue40;
    opacity: 0.5;
    height: 100%;
  }

  .current-overlay {
    background-image: linear-gradient(0deg, $keyBlue, rgba(74, 206, 127, 0));
    height: 50%;
  }

  .finished-overlay {
    background-image: linear-gradient(0deg, $keyGreen, rgba(74, 206, 127, 0));
    height: 50%;
  }
}
