@import "../../../assets/scss/colors";

.search-field-wrapper {


  input {
    border-radius: 1px;
    background-color: $white;
    border: 0;
    height: 45px;
    min-width: 350px;

    color: $waterBlue80;

    &::placeholder {
      color: $waterBlue40;
    }
  }
}
