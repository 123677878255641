@import "src/assets/scss/colors";

.persolog-element-display {
  .orderable {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    margin-bottom: 30px;

    padding: 30px;
    display: flex;

    align-items: center;

    .index {
      margin-right: 30px;
    }

    &.has-image {
      .text {
        align-items: center;
      }
    }

    &.is-dragging {
      opacity: 0.4;
    }

    &.can-drop {
      background-color: $keyBlue30;
    }

    &.is-over {
      background-color: $keyGreen20;
    }

    .text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 16px;
      line-height: 2;
      color: $darkBlue;


      img {
        max-width: 300px;
        margin-bottom: 20px;
      }
    }
  }

  .persolog-class-explanation {
    img {
      max-height: 200px;
    }
  }

  .persolog-progress-bar {
    .label {
      width: 20px;
      margin-right: 10px;
      align-items: center;
      text-align: center;
    }

    .progress-bar-container {
      background-color: #d3e5f4;
      flex-grow: 1;
    }

    .progress-bar {
      height: 25px;
    }

    .progress-bar-middle-mark {
      position: absolute;
      top: -3;
      padding-left: 30px;

      .middle-mark {
        height: 35px;
        width: 1px;
        border-width: 1px;
        border-style: solid
      }
    }
  }
}
