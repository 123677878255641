@import "src/assets/scss/colors";

.order-element-display {
  .orderable {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    margin-bottom: 30px;

    padding: 30px;
    display: flex;

    align-items: center;

    .index {
      margin-right: 30px;
    }

    &.has-image {
      .text {
        align-items: center;
      }
    }

    &.is-dragging {
      opacity: 0.4;
    }

    &.can-drop {
      background-color: $keyBlue30;
    }

    &.is-over {
      background-color: $keyGreen20;
    }

    .text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 16px;
      line-height: 2;
      color: $darkBlue;


      img {
        max-width: 300px;
        margin-bottom: 20px;
      }
    }
  }
}
