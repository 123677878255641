@import "src/assets/scss/colors";

.cart-contents-v2 {

  .course-icon, .course-icon-placeholder {
    width: 75px;
    height: 75px;
  }

  .ihk-icon {
    width: 40px
  }

  .cart-table-row {
    display: flex;
    position: relative;

    .row-title {
      font-size: 16px;
      font-weight: bold;
      color: $darkBlue;
      min-width: 75px;
    }

    hr {
      background-color: $waterBlue10;
    }
  }

  .already-owned {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .voucher-input-row {
    display: flex;
    flex-direction: column;

    .row-content {
      align-items: center;
      display: flex;

      input {
        height: 50px;
      }
    }
  }

  .courses {
    .cart-table-row:last-child hr {
      display: none;
    }
  }

  .upsell-option-row {
    background-color: rgba(#d3e5f4, 0.5);
    padding: 20px;

    hr {
      display: none;
    }
  }

  .price-renderer {
    display: flex;
    flex-direction: column;

    .price {
      text-align: right;
    }
  }

  .discount-item-row {
    display: flex;
    flex-direction: column;

    .discount-title {
      font-size: 16px;
      color: $darkBlue;
    }
  }

  .course-item-row, .upsell-option-row, .rates-row {
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    .course-item-row {
      .course-and-price {
        flex-direction: column;
      }

      .course-display {
        margin: 0 0 20px 0 !important;
      }


      .applied-discount {
        margin: 0 0 25px 0 !important;
      }

      .course-display {
        .tk-academy-text {
          font-size: 14px !important;
        }

        .tk-academy-large {
          font-size: 14px !important;
        }
      }
    }

    .monthly-price {
      text-align: right;
      font-size: 14px !important;
      max-width: 200px;
    }

    .upsell-option-row {
      button {
        margin-top: 25px;
      }
    }

    .discount-item-row, .voucher-input-row {
      .row-content {
        flex-wrap: wrap;
      }

      .row-title {
        width: 100%;
        margin-bottom: 15px;
      }

      .discount-title {
        font-size: 14px !important;
      }

      .input-wrapper {
        width: 100%;
        margin: 0 0 25px 0 !important;

        input {
          width: 100%;
        }
      }

      button {
        width: 100%;
      }
    }
  }


}

