.next-lesson-slider {

  .next-lessons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media (max-width: 1450px) {
      grid-template-columns: 1fr 1fr;
    }
  }


}
