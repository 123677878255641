@import "src/assets/scss/colors";

.questionnaire-element-display {

  button {
    margin-bottom: 20px;
  }

  .answer {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    font-size: 16px;
    line-height: 2;
    color: $darkBlue;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
  }

  .group {
    img {
      height: 70px;
      width: 70px;
    }
  }
}
