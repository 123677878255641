.lesson-info {
  .lesson-image {
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .lesson-description {
    margin-top: -25px;
    z-index: 2;
  }
}
