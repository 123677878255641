.academy-sidebar {
  width: 300px;

  .o2-logo {
    height: 50px;
    background-color: white;
    background-image: url("./o2-logo.png");
    background-size: 25px;
    background-repeat: no-repeat;
    background-position-x: 25px;
    background-position-y: 11px;

    &.collapsed {
      background-position-x: 15px;
    }
  }
}
