@import "../../../../../../assets/scss/colors";

.lesson-card {
  .course-image {
    max-width: 80px;
    border-radius: 5px;
  }

  .ihk-image {
    max-width: 32px;
  }

  .progress-bar-wrapper {
    border-top: 1px solid $waterBlue10;
  }
}
