.welcome {

  .welcome-foreground {
    z-index: 1;
  }

  .rank-image {
    height: 70px;
    width: 70px;
    border-radius: 35px;
  }
}
