@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?m7zq1m');
  src: url('fonts/icomoon.eot?m7zq1m#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?m7zq1m') format('truetype'),
  url('fonts/icomoon.woff?m7zq1m') format('woff'),
  url('fonts/icomoon.svg?m7zq1m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-a_arrow-down:before {
  content: "\e900";
}
.icon-a_arrow-left:before {
  content: "\e901";
}
.icon-a_arrow-right:before {
  content: "\e902";
}
.icon-a_arrow-up:before {
  content: "\e903";
}

.icon-a_back:before {
  content: "\e904";
}

.icon-a_back_circle:before {
  content: "\e905";
}

.icon-a_burger:before {
  content: "\e906";
}
.icon-a_clock:before {
  content: "\e907";
}
.icon-a_close:before {
  content: "\e908";
}
.icon-a_cross-box:before {
  content: "\e909";
}
.icon-a_definition:before {
  content: "\e90a";
}
.icon-a_dots:before {
  content: "\e90b";
}
.icon-a_empty_box:before {
  content: "\e90c";
}

.icon-a_exclamation:before {
  content: "\e90d";
}

.icon-a_exclamation-circle:before {
  content: "\e90e";
}

.icon-a_forward:before {
  content: "\e90f";
}

.icon-a_forward_circle:before {
  content: "\e910";
}

.icon-a_glas:before {
  content: "\e911";
}

.icon-a_info:before {
  content: "\e912";
}

.icon-a_key:before {
  content: "\e913";
}

.icon-a_lection:before {
  content: "\e914";
}

.icon-a_lock:before {
  content: "\e915";
}

.icon-a_lock_circle:before {
  content: "\e916";
}

.icon-a_logout:before {
  content: "\e917";
}

.icon-a_material:before {
  content: "\e918";
}

.icon-a_modules:before {
  content: "\e919";
}
.icon-a_notes:before {
  content: "\e91a";
}

.icon-a_inbox:before {
  content:"\e918";
}

.icon-a_qanda:before {
  content: "\e91b";
}
.icon-a_question:before {
  content: "\e91c";
}
.icon-a_question_circle:before {
  content: "\e91d";
}
.icon-a_quiz:before {
  content: "\e91e";
}
.icon-a_reload:before {
  content: "\e91f";
}

.icon-a_star:before {
  content: "\e920";
}

.icon-a_star_filled:before {
  content: "\e921";
}

.icon-a_text:before {
  content: "\e922";
}
.icon-a_tick:before {
  content: "\e923";
}
.icon-a_tick_box:before {
  content: "\e924";
}
.icon-a_tick_circle:before {
  content: "\e925";
}
.icon-a_trainer:before {
  content: "\e926";
}
.icon-a_trash:before {
  content: "\e927";
}
.icon-a_video:before {
  content: "\e928";
}
.icon-b_data-download:before {
  content: "\e929";
}
.icon-b_detail-view:before {
  content: "\e92a";
}
.icon-b_list-view:before {
  content: "\e92b";
}
.icon-b_read-text:before {
  content: "\e92c";
}
.icon-c_blanks:before {
  content: "\e92d";
}
.icon-c_info:before {
  content: "\e92e";
}
.icon-c_info_circle:before {
  content: "\e92f";
}
.icon-c_key-coin:before {
  content: "\e930";
}
.icon-c_multiple-choice:before {
  content: "\e931";
}
.icon-c_order:before {
  content: "\e932";
}
.icon-c_picture:before {
  content: "\e933";
}
.icon-c_sound:before {
  content: "\e934";
}
.icon-c_todo:before {
  content: "\e935";
}
.icon-d_courses:before {
  content: "\e936";
}
.icon-d_dashboard:before {
  content: "\e937";
}

.icon-d_imprint:before {
  content: "\e938";
}

.icon-d_mail:before {
  content: "\e939";
}

.icon-d_offline:before {
  content: "\e93a";
}

.icon-d_safety:before {
  content: "\e93b";
}

.icon-d_support:before {
  content: "\e93c";
}

.icon-e_password-hide:before {
  content: "\e93d";
}

.icon-e_password-show:before {
  content: "\e93e";
}

.icon-f_blog-01:before {
  content: "\e93f";
}

.icon-g_feedback:before {
  content: "\e940";
}

.icon-g_lernkonzept:before {
  content: "\e941";
}

.icon-g_zertifikat:before {
  content: "\e942";
}
