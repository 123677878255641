@import "src/assets/scss/colors";

.failed-orderable {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
  background-color: $white;
  margin-bottom: 30px;

  padding: 30px;
  display: flex;

  align-items: center;

  .index {
    margin-right: 30px;
  }

  .correct-index {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: solid 1.5px #d0dae4;
    background-color: white;
    height: 30px;
    width: 30px;
  }

  .correct-answer {
    color: white;
    background-color: $keyGreen;
    border: 1px solid $keyGreen;
  }

  .false-answer {
    color: $orange;
    background-color: $orange20;
    border: 1px solid $orange20;
  }

  &.has-image {
    .text {
      align-items: center;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 16px;
    line-height: 2;
    color: $darkBlue;


    img {
      max-width: 300px;
      margin-bottom: 20px;
    }
  }
}
