@import "../../../../../assets/scss/colors";

.lesson-card {
  .lesson-image {
    min-width: 245px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .overlay {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .progress-wrapper {
    max-width: 300px;
  }

  hr {
    border-color: $waterBlue10;
  }

  .academy-button {
    height: 35px;
  }

  .status-icon-wrapper {
    .icon {
      &:before {
        background-color: $white;
        background-size: 90%;
        border-radius: 50px;
      }
    }
  }
}
