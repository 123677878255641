@import "../../../../assets/scss/colors";

.tk-progress-bar {
  height: 5px;
  border-radius: 20px;
  width: 100%;
  position: relative;

  .inner-bar {
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .performance-limit {
    height: 5px;
    width: 2px;
    background-color: $waterBlue;
    position: absolute;
    top: 0
  }

  .dot {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: $white;
    top: -7px;
    box-shadow: 0 3px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .innerDotActive {
    height: 12px;
    width: 12px;
    border-radius: 5px;
    background-color: $keyGreen
  }

  .innerDotCurrent {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: $waterBlue80
  }

  .innerDotInactive {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: $keyGreen20
  }

}
