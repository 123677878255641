.payment-option-wrapper {
  .has-columns {
    display: grid;
    grid-template-columns: 20px 40px 1fr;
    grid-column-gap: 20px;
  }

  @media screen and (max-width: 1024px) {
    .explanation-wrapper.has-columns, .stripe-wrapper.has-columns {
      grid-template-columns: 0 0 1fr;
      grid-column-gap: 0;
    }
  }

  .always-visible {


    .image-wrapper {
      img {
        width: 45px;
        max-height: 25px;
      }
    }
  }
}
