@import "../../../assets/scss/colors";

.certificate-list {
  .certificate {
    display: grid;
    grid-template-columns: 150px 1fr 150px;

    a {
      color: $keyBlue;
    }
  }
}
