$lightBackground: #f3f5f8;
$darkBlue: #031d3c;
$waterBlue: #184276;
$keyBlue: #247ac7;
$keyBlue30: #a7cbe8;
$keyBlue20: #d3e5f4;

$waterBlue10: #e7ebf1;
$waterBlue20: #d0d9e3;
$waterBlue40: #a2b3c8;
$waterBlue80: #466791;
$keyGreen: #4ace7f;
$keyGreen20: #dcf8e7;
$orange: #ea561c;
$orange20: #fedecc;
$white: #ffffff;
$black: #000000;

.tk-bg-light {
  background-color: $lightBackground;
}

.tk-bg-green-20 {
  background-color: $keyGreen20;
}

.bgOrange20 {
  background-color: $orange20;
}

.tk-bg-waterblue {
  background-color: $waterBlue;
}

.tk-bg-gradient-top-down {
  background-image: linear-gradient(0deg, $keyGreen20, $keyBlue30);
}

.bgGradientLeftRight {
  background-image: linear-gradient(to left, $keyGreen20, $keyBlue30);
}

.tk-bg-keygreen {
  background-color: $keyGreen;
}

.tk-bg-keygreen-20 {
  background-color: $keyGreen20;
}

.tk-bg-keyblue {
  background-color: $keyBlue;
}

.tk-color-keyblue {
  color: $keyBlue;
}

.tk-bg-keyblue-20 {
  background-color: $keyBlue20;
}

.tk-bg-darkblue {
  background-color: $darkBlue;
}

.tk-color-darkblue {
  color: $darkBlue;
}

.tk-color-waterblue {
  color: $waterBlue;
}

.tk-color-waterblue-80 {
  color: $waterBlue80;
}

.tk-color-waterblue-20 {
  color: $waterBlue20;
}

.tk-bg-waterblue-80 {
  background-color: $waterBlue80;
}

.tk-bg-waterblue-40 {
  background-color: $waterBlue40;
}

.tk-bg-waterblue-20 {
  background-color: $waterBlue20;
}

.tk-bg-waterblue-10 {
  background-color: $waterBlue10;
}

.tk-color-waterblue-40 {
  color: $waterBlue40;
}

.waterBlue20 {
  color: $waterBlue20;
}

.keyBlue {
  color: $keyBlue
}

.tk-key-blue-30 {
  color: $keyBlue30;
}

.tk-color-keygreen {
  color: $keyGreen;
}

.tk-color-orange {
  color: $orange;
}

.tk-bg-orange {
  background-color: $orange;
}

.tk-bg-orange-20 {
  background-color: $orange20;
}


.tk-white {
  color: $white;
}

.tk-black {
  color: $black;
}

.tk-bg-white {
  background-color: $white;
}

.tk-white-on-hover {
  &:hover {
    color: $white !important;

    > li {
      color: $white !important;
    }
  }
}
