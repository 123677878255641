.learn-footer {

  .status-display {

    &.failure-display {
      img {
        margin-left: 50px;
        margin-right: 50px;
      }
    }

    img {
      max-height: 200px;
    }

    div {
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0.2px;
      margin-bottom: 25px;
    }
  }

  .tk-skip-button {
    background: transparent;
    border: 1px solid #466890 !important;
  }
}
