@import "../../../../assets/scss/colors";

.result-checkbox-wrapper {
  .result-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: solid 1.5px #d0dae4;
    background-color: white;
    height: 30px;
    width: 30px;
  }

  .correct-and-checked {
    color: white;
    background-color: $keyGreen;
    border: 1px solid $keyGreen;
  }

  .correct-and-not-checked {
    background-color: transparent !important;
    border: 1px solid $keyGreen;
    color: $keyGreen
  }

  .false-but-checked {
    background-color: $orange20;
    border: 1px solid $orange20;
    color: $orange
  }

  .correctAnswer {
    border-left: 5px solid $keyGreen;
  }
}



