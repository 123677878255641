.news-card {
  .tk-image {
    display: flex;
    min-height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-position: center;
    background-size: cover;
  }
}
