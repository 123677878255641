.login-screen {
  .login-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url("../../../../assets/images/login-background.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .login-foreground {
    z-index: 1;
  }
}
