.payment-methods {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 35px;
  grid-row-gap: 25px;

  img {
    max-width: 70px;
    max-height: 60px;
  }

  .method-icon {
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #c9d4df;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
