@import "src/assets/scss/colors";

.base-element-display {
  padding: 5rem;
  overflow: auto;

  .wrapper {
    max-width: 1280px;
  }
}
