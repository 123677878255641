@import "../../../../assets/scss/colors";

.checkbox-wrapper {
  .checkbox {
    border-radius: 5px;
    border: solid 1.5px #d0dae4;
    background-color: white;
    height: 30px;
    width: 30px;

    &.is-checked {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: $keyBlue;
      border: 0;
    }
  }
}
