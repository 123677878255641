@import "../../../../assets/scss/colors";

.video-player {
  display: flex;

  justify-content: center;
  align-items: center;

  &.fullscreen {
    padding: 0;
  }

  .player-wrapper {
    position: relative;
  }

  .video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 25px;

    &.stopped {
      background-color: rgba($darkBlue, 0.2);
    }

    .play-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 20%;
      }
    }

    .upper-controls {
      display: flex;

      .rate {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin-left: auto;
        border: 1px solid $white;
        border-radius: 15px;
        background-color: transparent;
        color: $white;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        padding: 2px 15px;
        text-align-last: center;

        &:focus {
          outline: none;
        }
      }
    }

    .lower-controls {
      display: flex;

      .timestamp {
        color: $white;
      }

      .fullscreen {
        margin-left: auto;
        height: 20px;
        width: 20px;
        display: flex;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .seek {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: flex-end;

      .bar {
        height: 5px;
        display: flex;
        width: 100%;
        align-items: center;

        .bar-fill {
          background-color: $keyGreen;
          height: 5px;
        }

        .bubble {
          height: 15px;
          width: 15px;
          border-radius: 10px;
          background-color: $keyGreen;
        }
      }
    }
  }
}
