@import "../../../assets/scss/colors";

.note-modal {
  max-width: 650px !important;

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
    border: 0;
    position: relative;
    background-color: $white;

    .base-element-display {
      padding: 0 0 10px;
    }
  }
}
