.qna-item {

  .question {
    background-color: #d3e5f6;
  }

  .question, .answer {
    border-radius: 5px;
  }

  .answer {
    background-color: white;
  }
}
